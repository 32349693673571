import {defineStore} from 'pinia'
import {useAuthStore} from "@/stores/auth";
import {dialogFlowAxios} from "@/main";
import {useApiSettingStore} from "@/stores/apiSettings";
import {useSettings} from "@/stores/settings";

export const useEntityStore = defineStore({
    id: 'entity',

    state: () => ({
        addEntity: false,
        entitiesType: null,
        nameEntityClickFromList: '',
        entityType: null,
        errorMessage: ''
    }),
    actions: {
        getEntitiesType() {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + useAuthStore().bearerTokenGetters
                    }
                }
                useApiSettingStore().apiSettingsGetters.pagination.page_size = (Math.round((document.documentElement.scrollHeight - 600) / 65.5) + 3)

                dialogFlowAxios.get('v2/projects/' + useSettings().serviceAccountCredentialsGetters.setting_records.project_id.value + '/agent/entityTypes', config)
                    .then(res => {
                        this.entitiesType = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        } else if (err.response.data.error.code === 429) {
                            useApiSettingStore().error500 = err.response.data.error.message
                        }
                        reject(err)
                    })
            })
        },
        getEntityType(name) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Bearer ' + useAuthStore().bearerTokenGetters
                    }
                }
                dialogFlowAxios.get('v2/' + name, config)
                    .then(res => {
                        this.entityType = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        batchDeleteEntityType(arrayIntent) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + useAuthStore().bearerTokenGetters
                    }
                }
                dialogFlowAxios.post('v2/projects/' + useSettings().serviceAccountCredentialsGetters.setting_records.project_id.value + '/agent/entityTypes:batchDelete', {
                    "entityTypeNames": arrayIntent
                }, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        } else if (err.response.data.error.code === 429) {
                            useApiSettingStore().error500 = err.response.data.error.message
                        } else if (err.response.data.error.code === 400) {
                            useApiSettingStore().error500 = err.response.data.error.message
                        }
                        reject(err)
                    })
            })
        },
        deleteEntity(entity) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + useAuthStore().bearerTokenGetters
                    }
                }
                dialogFlowAxios.delete('v2/' + entity, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        editEntity(payload) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + useAuthStore().bearerTokenGetters
                    }
                }
                dialogFlowAxios.patch('v2/' + payload.name, payload, config)
                    .then(res => {
                        this.entityType = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        this.errorMessage = err.response.data.error.message
                        reject(err)
                    })
            })
        },
        addNewEntityType(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + useAuthStore().bearerTokenGetters
                    }
                }
                dialogFlowAxios.post('v2/' + 'projects/' + useSettings().serviceAccountCredentialsGetters.setting_records.project_id.value + '/agent/entityTypes', payload, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        this.errorMessage = err.response.data.error.message
                        reject(err)
                    })
            })
        }
    },

    getters: {
        entityTypeGetters: state => {
            return state.entityType
        },
        entitiesTypeGetters: state => {
            return state.entitiesType
        },
        addEntityGetters: state => {
            return state.addEntity
        },
        nameEntityClickFromListGetters: state => {
            return state.nameEntityClickFromList
        },
        errorMessageGetters: state => {
            return state.errorMessage
        }
    }
})


