import {defineStore} from 'pinia'
import {mainAxios} from "@/main";
import axios from "axios";


export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        bearerToken: null,
        user: null,
        errorToken: '',
        error: ''
    }),
    actions: {
        login(user) {
            return new Promise((resolve, reject) => {
                mainAxios.post("auth/", user)
                    .then(res => {
                        this.user = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response) {
                            this.error = err.response.data
                        }
                        reject(err)
                    })
            })
        },
        loginForAccessToken(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
                axios.post('https://oauth2.googleapis.com/token', payload, config)
                    .then(res => {
                        useAuthStore().bearerToken = res.data.access_token
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    },
    getters: {
        isAuthenticated: state => {
            return !!state.user
        },
        bearerTokenGetters: state => {
            return state.bearerToken
        },
        userGetters: state => {
            return state.user
        },
        errorGetters: state => {
            return state.error
        },
        errorTokenGetters: state => {
            return state.errorToken
        }
    }
})


