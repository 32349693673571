import {defineStore} from 'pinia'
import {mainAxios} from "@/main";
import {useApiSettingStore} from "@/stores/apiSettings";
import {useAuthStore} from "@/stores/auth";

export const useNotificationsStore = defineStore({
    id: 'notification',

    state: () => ({
        notification: null,
        notifications: null,
        notificationNewest: 0,
        notificationNewestSound: 0,
    }),
    actions: {
        getNotifications(payload) {
            return new Promise((resolve, reject) => {
                console.log("aca si");

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                let url = new URL(mainAxios.defaults.baseURL + 'api/notifications/')

                if (payload.queryParams !== null) {
                    console.log("aca si 2");
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }

                    url.search = params
                    mainAxios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.notifications = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    mainAxios.get(url.pathname, config)
                        .then(res => {
                            this.notifications = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

                // mainAxios.get(url.pathname + payload, config)
                //     .then(res => {
                //         this.notifications = res.data
                //         resolve(res)
                //     })
                //     .catch(err => {
                //         if (err.response && err.response.status === 500) {
                //             useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                //         }
                //         reject(err)
                //     })
            })
        },
        getNotification(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.get('api/notifications/' + payload + '/', config)
                    .then(res => {
                        this.notification = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        editNotifications(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.patch('api/notifications/' + payload.id + '/', payload, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getNotificationsNewest() {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.get('/api/notifications/newest', config)
                    .then(res => {
                        this.notificationNewest = res.data
                        this.soundNotifications();
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        soundNotifications() {
            if (this.notificationNewest.total > 0) {
                if (this.notificationNewest.total > this.notificationNewestSound) {
                    this.notificationNewestSound = this.notificationNewest.total;
                    var notification = new Audio('./sounds/noti.mp3');
                    notification.loop = false;
                    if (notification != undefined) notification?.play();
                }
            }
        }
    },
    getters: {
        notificationGetters: state => {
            return state.notification
        },
        notificationsGetters: state => {
            return state.notifications
        },
        notificationsNewestGetters: state => {
            return state.notificationNewest
        }
    }
})


