import {defineStore} from 'pinia'
import {useAuthStore} from "@/stores/auth";
// import {useEmployeesStore} from "@/stores/employees";
import {useApiSettingStore} from "@/stores/apiSettings";
import router from "@/router";
import {mainAxios} from "@/main";

export const useUserStore = defineStore("user", {
    state: () => ({
        users: null,
        arrayUsersInfinity: [],
        optionsUser: null,
        user: null,
        searchList: '',
        currentPageList: 1,
        countTotList: 0,
        spinnerUser: false
    }),
    actions: {
        getOptionsUser() {
            return new Promise((resolve, reject) => {
                mainAxios.options('api/users/')
                    .then(res => {
                        this.optionsUser = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getUser(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.get('api/users/' + id + '/', config)
                    .then(res => {
                        this.user = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getUsers(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(mainAxios.defaults.baseURL + 'api/users/')

                this.spinnerUser = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    mainAxios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.users = res.data
                            if (payload.queryParams.scroll) {
                                this.arrayUsersInfinity.push(...res.data.results)
                            } else {
                                this.arrayUsersInfinity = res.data.results
                            }
                            this.spinnerUser = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerUser = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    mainAxios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerUser = false
                            this.users = res.data
                            let a = []
                            a.push(...res.data.results);
                            this.arrayUsersInfinity = a
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerUser = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },
        registerWithoutDetail(payload) {
            return new Promise((resolve, reject) => {
                mainAxios.post('/api/users/', {
                    username: payload.values.username,
                    email: payload.values.email,
                    password: payload.values.password,
                    confirmation: payload.values.confirmation,
                    user_type: payload.user_type
                })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        register(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.post('/api/users/', {
                    username: payload.values.username,
                    email: payload.values.email,
                    password: payload.values.password,
                    confirmation: payload.values.confirmation,
                    user_type: payload.user_type
                })
                    .then(res => {
                        mainAxios.post('/api/users-details/', {
                            first_name: payload.values.first_name,
                            last_name: payload.values.last_name,
                            entity_name: payload.entity_name,
                            custom_user: res.data.id
                        }, config)
                            .then(() => {
                                res.data.registration_completed = true
                                useUserStore().editUser(res.data)
                                    .then(res => {
                                        resolve(res)
                                    })
                                    .catch(() => {
                                    })
                            })
                            .catch(() => {
                                useUserStore().deleteUser(res.data.id)
                            })
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })

        },
        editUser(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.patch('api/users/' + payload.id + '/', payload, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        editDetail(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                if (payload.user_type === 'tom' || payload.user_type === 'cm') {
                    mainAxios.patch('api/users-details/' + payload.user_detail.id + '/', payload.user_detail, config)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else if (payload.user_type === 'b' || payload.user_type === 'c') {
                    mainAxios.patch('api/businesses-details/' + payload.user_detail.id + '/', payload.user_detail, config)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else if (payload.user_type === 'ca') {
                    mainAxios.patch('api/public-administrations-details/' + payload.user_detail.id + '/', payload.user_detail, config)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }
            })
        },
        registrationComplete(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                if (useAuthStore().userGetters.user_type === 'tom' || useAuthStore().userGetters.user_type === 'cm') {
                    mainAxios.post('api/users-details/', payload, config)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else if (useAuthStore().userGetters.user_type === 'b' || useAuthStore().userGetters.user_type === 'c') {
                    mainAxios.post('api/businesses-details/', payload, config)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else if (useAuthStore().userGetters.user_type === 'ca') {
                    mainAxios.post('api/public-administrations-details/', payload, config)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }
            })
        },
        deleteUser(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.delete('/api/users/' + id + '/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        changePassword(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.patch('api/users/change-password/', {
                    old_password: payload.old_password,
                    password: payload.password,
                    confirmed_password: payload.confirmed_password,
                }, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        setPassword(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.patch('api/users/' + payload.id + '/set-password/', {
                    password: payload.values.password,
                    confirmed_password: payload.values.confirmed_password
                }, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        requestResetPassword(payload) {
            return new Promise((resolve, reject) => {
                mainAxios.post('api/users/request-reset-password/', {
                    email: payload
                })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        checkRequestResetPassword(payload) {
            return new Promise((resolve, reject) => {
                mainAxios.get('api/users/check-request-reset-password/' + payload.uidb64 + '/' + payload.token)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            router.push('/login').catch(() => {
                            })
                        }
                        reject(err)
                    })
            })
        },
        resetPassword(payload) {
            return new Promise((resolve, reject) => {
                mainAxios.patch('api/users/reset-password/', {
                    password: payload.password,
                    token: payload.token,
                    uidb64: payload.uidb64
                })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        deactivateUser(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.get('api/users/' + payload + '/deactivate/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        activateUser(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.get('api/users/' + payload + '/activate/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        }
    },
    getters: {
        usersGetters: state => {
            return state.users
        },
        userGetters: state => {
            return state.user
        },
        arrayUsersInfinityGetters: state => {
            return state.arrayUsersInfinity
        },
        spinnerUserGetters: state => {
            return state.spinnerUser
        },
        optionsUserGetters: state => {
            return state.optionsUser
        },
        searchListGetters: state => {
            return state.searchList
        },
        currentPageListGetters: state => {
            return state.currentPageList
        },
        countTotListGetters: state => {
            return state.countTotList
        }
    }
})