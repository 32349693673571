import {defineStore} from 'pinia'
import {mainAxios} from "@/main";
import {useApiSettingStore} from "@/stores/apiSettings";
import {useAuthStore} from "@/stores/auth";

export const useDocumentStore = defineStore({
    id: 'document',

    state: () => ({
        arrayDocuments: [],
        arrayWithNewDocuments: [],
        documents: null,
        optionsDocument: null,
        addDocument: false,
        document: null,
    }),
    actions: {
        getDocuments(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(mainAxios.defaults.baseURL + 'api/documents/')


                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    mainAxios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.documents = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    mainAxios.get(url.pathname, config)
                        .then(res => {
                            this.documents = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }
            })
        },
        getDocument(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.get('api/documents/' + payload + '/', config)
                    .then(res => {
                        this.document = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createDocument(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.post('api/documents/', payload, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        bulkDeleteDocument(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.post('api/documents/bulk-destroy/', {ids: payload}, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        editDocument(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.patch('api/documents/' + payload.id + '/', payload.formData, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        deleteDocument(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.delete('api/documents/' + payload + '/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getOptionsDocument() {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.options('api/documents/', config)
                    .then(res => {
                        this.optionsDocument = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }

    },

    getters: {
        documentsGetters: state => {
            return state.documents
        },
        documentGetters: state => {
            return state.document
        },
        optionsDocumentGetters: state => {
            return state.optionsDocument
        },
        addDocumentGetters: state => {
            return state.addDocument
        },
        arrayDocumentsGetters: state => {
            return state.arrayDocuments
        },
        arrayWithNewDocumentsGetters: state => {
            return state.arrayWithNewDocuments
        }
    }
})


