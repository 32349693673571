import {defineStore} from 'pinia'
import {mainAxios} from "@/main";
import {useApiSettingStore} from "@/stores/apiSettings";
import {useAuthStore} from "@/stores/auth";

export const useFormRecordStore = defineStore({
    id: 'formRecord',

    state: () => ({
        addFormRecord: false,
        formRecords: null,
        optionsFormRecord: null,
        formRecordsDirectories: null,
        formRecordDirectory: null,
        currentDirectory: null,
        formRecord: null,
    }),
    actions: {
        getFormRecordsDirectories(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(mainAxios.defaults.baseURL + 'api/form-records/directories/')

                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    mainAxios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.formRecordsDirectories = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    mainAxios.get(url.pathname, config)
                        .then(res => {
                            this.formRecordsDirectories = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }
            })
        },
        getFormRecordDirectory(payload, params = "") {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.get('api/form-records/' + payload + '/directories-content/' + params, config)
                    .then(res => {
                        this.formRecordDirectory = res.data
                        if (this.formRecordDirectory.results.length !== 0) {
                            this.currentDirectory = this.formRecordDirectory.results[0];
                        }
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response) {
                            if (err.response.status == 404) {
                                this.formRecordDirectory = []
                                resolve([])
                            } else {
                                reject(err)
                            }
                        } else {
                            reject(err)
                        }
                    })
            })
        },
        getFormRecords(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(mainAxios.defaults.baseURL + 'api/form-records/')

                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    mainAxios.get(url.pathname + url.search, config)
                        .then(res => {
                            let formRecordsResults = res.data.results.map(record => ({
                                id: record.id,
                                form: {name: record.form.name},
                                formattedDate: record.createdAt,
                            }));
                            this.formRecords = res.data;
                            this.formRecords.results = formRecordsResults;
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    mainAxios.get(url.pathname, config)
                        .then(res => {
                            let formRecordsResults = res.data.results.map(record => ({
                                id: record.id,
                                form: {name: record.form.name},
                                formattedDate: record.createdAt,
                            }));
                            this.formRecords = res.data;
                            this.formRecords.results = formRecordsResults;
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }
            })
        },
        getFormRecord(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.get('api/form-records/' + payload + '/', config)
                    .then(res => {
                        this.formRecord = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createFormRecord(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.post('api/form-records/', payload, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        editFormRecord(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.patch('api/form-records/' + payload.id + '/', payload, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        deleteFormRecord(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.delete('api/form-records/' + payload + '/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getOptionsFormRecord() {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.options('api/form-records/', config)
                    .then(res => {
                        this.optionsFormRecord = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    },

    getters: {
        formRecordsGetters: state => {
            return state.formRecords
        },
        formRecordGetters: state => {
            return state.formRecord
        },
        addFormRecordGetters: state => {
            return state.addFormRecord
        },
        optionsFormRecordGetters: state => {
            return state.optionsFormRecord
        },
        formRecordsDirectoriesGetters: state => {
            return state.formRecordsDirectories
        },
        formRecordDirectoryGetters: state => {
            return state.formRecordDirectory
        },
        currentDirectoryGetters: state => {
            return state.currentDirectory
        },

    }
})


