import {defineStore} from 'pinia'
import {mainAxios} from "@/main";
import {useApiSettingStore} from "@/stores/apiSettings";
import {useAuthStore} from "@/stores/auth";

export const useFeedbackStore = defineStore({
    id: 'feedback',

    state: () => ({
        addFeedback: false,
        feedbacks: null,
        optionsFeedback: null,
        feedback: null,
    }),
    actions: {
        getFeedbacks(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(mainAxios.defaults.baseURL + 'api/feedbacks/')

                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    mainAxios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.feedbacks = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    mainAxios.get(url.pathname, config)
                        .then(res => {
                            this.feedbacks = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }
            })
        },
        getFeedback(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.get('api/feedbacks/' + payload + '/', config)
                    .then(res => {
                        this.feedback = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getFeedbackByName(name) {
            return new Promise((resolve, reject) => {
                mainAxios.get('api/feedbacks/?name=' + name)
                    .then(res => {
                        this.feedback = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        sendFeedbackRecords($records){
            return new Promise((resolve, reject) => {

                mainAxios.post('api/feedback-records/', $records)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createFeedback(payload) {
            return new Promise((resolve, reject) => {

                mainAxios.post('api/feedbacks/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        editFeedback(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.patch('api/feedbacks/' + payload.id + '/', payload, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        deleteFeedback(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.delete('api/feedbacks/' + payload + '/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        bulkDeleteFeedback(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.post('/api/feedbacks/bulk-destroy/',{
                    ids: payload
                },config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getOptionsFeedback() {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                mainAxios.options('api/feedbacks/', config)
                    .then(res => {
                        this.optionsFeedback = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }

    },

    getters: {
        feedbacksGetters: state => {
            return state.feedbacks
        },
        feedbackGetters: state => {
            return state.feedback
        },
        addFeedbackGetters: state => {
            return state.addFeedback
        },
        optionsFeedbackGetters: state => {
            return state.optionsFeedback
        }

    }
})
