<template>
  <div :class="{}" class="container-app">
    <ComponentSideBar
        v-if="!storeApiSettings.hamburgerStatusGetters && storeAuth.bearerTokenGetters"></ComponentSideBar>
    <ComponentSideBar class="container-hamburger"
                      v-if="storeApiSettings.hamburgerStatusGetters && storeAuth.bearerTokenGetters"></ComponentSideBar>
    <div :class="{'container_content_app': !storeAuth.isAuthenticated}" class="container-content-app">
      <ComponentHeader v-if="storeAuth.isAuthenticated && storeAuth.bearerTokenGetters"></ComponentHeader>
      <router-view></router-view>
    </div>
  </div>
  <div v-if="storeApiSettings.overlayModalGetters"
       class="overlay-custom">

    <b-modal @close="resetStateStoreModal" @hide="resetStateStoreModal" title="Conferma eliminazione"
             :hide-footer="true"
             v-model="showModal">
      <div class="container-content-modal">
        <img src="./assets/shared/alert_delete.svg" alt="">
        <span v-if="storeApiSettings.messageToDeleteGetters"
              v-html="storeApiSettings.messageToDeleteGetters"></span>
      </div>
      <div class="container-buttons">
        <button @click="resetStateStoreModal" class="btn btn-cancel-custom">Annulla</button>
        <button @click="deleteObject" class="btn btn-danger-custom">
          <span v-if="!storeApiSettings.spinnerDeletingGetters">Elimina definitivamente</span>
          <b-spinner v-else></b-spinner>
        </button>
      </div>
    </b-modal>
    <b-modal title="Conferma" :hide-footer="true"
             v-model="showModalBack">
      <div class="container-content-modal">
        <img src="./assets/shared/alert_delete.svg" alt="">
        <span>Sei sicuro di voler navigare lontano da questa pagina senza salvare?</span>
      </div>
      <div class="container-buttons">
        <button @click="resetStateStoreModal" class="btn btn-cancel-custom">Annulla</button>
        <button @click="storeApiSettings.functionBack" class="btn btn-success">
          <span v-if="!storeApiSettings.spinnerDeletingGetters">Confermare</span>
          <b-spinner v-else></b-spinner>
        </button>
      </div>
    </b-modal>
  </div>
</template>


<script>
import ComponentSideBar from "@/components/sidebar/ComponentSideBar";
import {useAuthStore} from "@/stores/auth";
import ComponentHeader from "@/components/header/ComponentHeader";
import {useApiSettingStore} from "@/stores/apiSettings";
import {calculateMargin} from "@/main";
import {useIntentStore} from "@/stores/intents";
import {useEntityStore} from "@/stores/entity";
import {useFormStore} from "@/stores/forms";
import {useDocumentStore} from "@/stores/documents";
import {useSettings} from "@/stores/settings";

export default {
  name: 'App',
  components: {ComponentHeader, ComponentSideBar},
  setup() {
    const storeAuth = useAuthStore()
    const storeApiSettings = useApiSettingStore()

    useSettings().getSettings({queryParams: null})
        .then(() => {
        })
        .catch(() => {
        })
    if (useAuthStore().isAuthenticated) {
      useSettings().getServiceAccountCredentials()
          .then(() => {
          })
          .catch(() => {
          })
    }
    useApiSettingStore().getApiSettings()
        .then(() => {
        })
        .catch(() => {
        })
    return {
      storeAuth,
      storeApiSettings
    }
  },
  data() {
    return {
      showModal: false,
      showModalBack: true,
    }
  },
  created() {
    this.resetStateStoreModal()
    useIntentStore().genericAction = false

    if (window.innerWidth < window.innerHeight) {
      this.changeVariables()
    } else {
      this.resetVariables()
    }

    useApiSettingStore().getApiSettings()
        .then(() => {
        })
        .catch(() => {
        })

    useSettings().getSettings({queryParams: null})
        .then(() => {
        })
        .catch(() => {
        })
    useApiSettingStore().error500 = ''
    useIntentStore().addIntent = false
    useFormStore().addForm = false
    useDocumentStore().addDocument = false
    useApiSettingStore().overlayModal = false
    useApiSettingStore().hamburgerStatus = false
    useEntityStore().addEntity = false
    useSettings().addSetting = false
  },
  computed: {
    confirmDelete() {
      return useApiSettingStore().confirmDeleteGetters
    },
    showBack() {
      return useApiSettingStore().showBackGetters
    },
    genericAction() {
      return useIntentStore().genericActionGetters
    }
  },
  watch: {
    confirmDelete: {
      handler: function (value) {
        this.showModal = value
      }, deep: true
    },
    showBack: {
      handler: function (value) {
        this.showModalBack = value
      }, deep: true
    },
    genericAction: {
      handler: function (value) {
        if (value) {
          setTimeout(() => {
            useIntentStore().genericAction = false
          }, 10000)
        }
      }
    }
  },
  methods: {
    deleteObject() {
      // console.log(useApiSettingStore().typeObjectToDeleteGetters);
      if (useApiSettingStore().typeObjectToDeleteGetters === 'document') {
        this.emitter.emit('canDeleteDocument')
      } else if (useApiSettingStore().typeObjectToDeleteGetters === 'intent') {
        this.emitter.emit('canDeleteIntent')
      } else if (useApiSettingStore().typeObjectToDeleteGetters === 'form-record') {
        this.emitter.emit('canDeleteFormRecord')
      } else if (useApiSettingStore().typeObjectToDeleteGetters === 'field') {
        this.emitter.emit('canDeleteField')
      } else if (useApiSettingStore().typeObjectToDeleteGetters === 'intents') {
        this.emitter.emit('canDeleteAllIntents')
      } else if (useApiSettingStore().typeObjectToDeleteGetters === 'entities') {
        this.emitter.emit('canDeleteAllEntities')
      } else if (useApiSettingStore().typeObjectToDeleteGetters === 'entity') {
        this.emitter.emit('canDeleteEntity')
      }
    },
    resetStateStoreModal() {
      this.showModal = false
      useApiSettingStore().messageToDelete = ''
      useApiSettingStore().confirmDelete = false
      useApiSettingStore().showBack = false
      useApiSettingStore().objectToDelete = null
    },
    changeVariables() {
      document.documentElement.style.setProperty('--x-small', '1.4vmax');
      document.documentElement.style.setProperty('--small', '1.7vmax');
      document.documentElement.style.setProperty('--medium', '2.6vmax');
      document.documentElement.style.setProperty('--large', '3.5vmax');

    },
    resetVariables() {
      document.documentElement.style.setProperty('--x-small', '1.4vmin');
      document.documentElement.style.setProperty('--small', '1.7vmin');
      document.documentElement.style.setProperty('--medium', '2.6vmin');
      document.documentElement.style.setProperty('--large', '3.5vmin');
    }
  },
  mounted() {
    calculateMargin()
    window.addEventListener('resize', (e) => {

      if (e.target.innerWidth > 991.98) {
        useApiSettingStore().hamburgerStatus = false
        useApiSettingStore().overlayModal = false
        calculateMargin()
      } else {
        let container_content_app = document.getElementsByClassName('container-content-app')[0]
        container_content_app.style.marginLeft = '0px'
      }
      if (window.innerWidth < window.innerHeight) {
        this.changeVariables()
      } else {
        this.resetVariables()
      }
    })
  },
  updated() {
    calculateMargin()
  }
}
</script>

<style>
.container-buttons {
  display: flex;
  align-items: center;
  margin: 20px 0px 0px 0px;
}

.container-content-modal img {
  margin-right: 10px;
}

.container-content-modal {
  display: flex;
  align-items: center;
}

.container-hamburger {
  position: fixed !important;
  z-index: 100 !important;
  display: block !important;
  width: 70vw !important;
}

.container-content-app {
  display: flex;
  flex-direction: column;
  /*width: 100%;*/
  min-height: 100vh;
  height: 100vh;
}

.container_app {
  flex-direction: column;
  min-height: 100vh;
}

.container-app {
  /*display: flex;*/
}

/*// X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {

}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {

}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .container_app {
    flex-direction: column;
    min-height: 100vh;
  }

  .container_content_app {
    height: 100%;
  }
}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {

}
</style>
